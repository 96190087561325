import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo-component/seo';
//import LoginPage from '../pages/login';

import ResouceHubLanding from '../components/ResourceHubLanding';

export default function InsightHubPage({location ,pageContext, data}) {
  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };

  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };


  return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulCustomResourceHub?.seoEntry}
          siteurl = {pageContext?.slug}
          bucketName = {pageContext?.bucketName}
          basePath={pageContext.basePath}
          organizationName = {pageContext?.appData?.organizationName}
          organizationLogo = {pageContext?.appData?.organizationLogo?.file?.url}
          organizationLinkedinLink = {pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink = {pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink = {pageContext?.appData?.organizationYoutubeLink}
          location={location}
        />

        <ResouceHubLanding
          researchData={data.allContentfulResearch.nodes}
          podcastData={data.allContentfulPodcast.nodes}
          resourceHubData={data.contentfulCustomResourceHub}
          videoData={data.allContentfulCustomVideo.nodes}
          blogData={data.allContentfulBlogs.nodes}
          basePath={pageContext.basePath}
          eventsAndWebinarData={data.allContentfulEventsAndWebinar.nodes}
          backgroundImg={data?.contentfulComponentBackgroundImages}
        />
      </Layout>
    </>
  );
}
export const query = graphql`
  query resourceHubQuery($contentful_id: String, $locale: String) {
    contentfulComponentBackgroundImages {
      hoverLeftArrowImage {
        file {
          url
        }
      }
      hoverRightArrowImage {
        file {
          url
        }
      }
      leftArrowImage {
        file {
          url
        }
      }

      rightArrowImage {
        file {
          url
        }
      }
    }

    allContentfulEventsAndWebinar(filter: {node_locale: {eq: $locale}}) {
      nodes {
        node_locale
        imageOrVideo
        region
        contentful_id
        categoryList {
          categoryName
        }
        buttonLabel
        dateOfEvent
        dateOfPublishing
        eventsOrWebinarTitle
        pageVisible
        shortSummary {
          raw
        }
        video {
          videoId
          videoType
        }
        slug
        eventThumbnailImage {
          file {
            url
          }
          description
        }
      }
    }
    allContentfulCustomVideo(filter: {node_locale: {eq: $locale}}) {
      nodes {
        contentful_id
        entryTitle
        videoComponent {
          contentful_id
          createdAt
          entryTitle
          videoId
          videoType
          videoTitle
          dateOfPublishing
        }
      }
    }
    allContentfulPodcast(filter: {node_locale: {eq: $locale}}) {
      nodes {
        contentful_id
        entryTitle
        podcastSlug
        podcastTitle
        pageVisible
        publishedDate
        region
        categoryList {
          categoryName
        }
        thumbnailImage {
          description
          title
          file {
            url
          }
        }
      }
    }
    allContentfulResearch(filter: {node_locale: {eq: $locale}}) {
      nodes {
        id
        entryTitle
        contentful_id
        researchSlug
        featuredInResourceHub
        researchTitle
        pageVisible
        region
        categoryList {
          categoryName
        }
        thumbnailImage {
          description
          file {
            url
          }
        }
        dateOfPublishing
        resourceType
        shortDescription {
          raw
        }
        thumbnailImage {
          description
          file {
            url
          }
        }
        document { 
          file {
            url
          }
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
    }
    allContentfulBlogs(filter: {node_locale: {eq: $locale}}) {
      nodes {
        id
        entryTitle
        contentful_id
        blogSlug
        featured
        news
        pageVisible
        blogContent {
          ... on ContentfulShortBlog {
            id
            contentful_id
            dontDisplayPublishedDateInBlogLandingTile
            dateOfPublication(locale: "US")
            shortBlogTitle
            thumbnailImage {
              description
              file {
                url
              }
            }
            description {
              raw
            }
            region
            categoryList {
              categoryName
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulLongBlog {
            id
            contentful_id
            longBlogTitle
            dontDisplayPublishedDateInBlogLandingTile
            dateOfPublication(locale: "US")
            thumbnailImage {
              description
              file {
                url
              }
            }
            description {
              raw
            }
            region
            categoryList {
              categoryName
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulPodcastBlog {
            id
            contentful_id
            dontDisplayPublishedDateInBlogLandingTile
            dateOfPublication(locale: "US")
            podcastBlogTitle
            description {
              raw
            }
            podcastBlogTileImage {
              description
              file {
                url
              }
              title
            }
            region
            categoryList {
              categoryName
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
    }
    contentfulCustomResourceHub(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      entryTitle
      videosTitle
      moreVideos {
        label
        slug
      }
      podcastsTitle
      morePodcasts {
        slug
        label
      }
      eventsAndWebinarTitle
      moreEventsWebinars {
        slug
        label
      }
      blogsTitle
      blogsAutoRotate
      moreBlogs {
        label
        slug
      }
      moreResearch {
        label
        slug
      }
      researchTitle
      contentful_id
      primaryTitle
      secondaryTitle
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          description
          file {
            url
          }
        }
      }
      salesCollateralTitle
      resourceHubSalesCollateral {
        description {
          raw
        }
        header{
          raw
        }     
        pdfOrDocument {
          description
          file {
            url
          }
        }
        tileLink {
          anchorLink
          slug
        }
        linkOrPdf
        backgroundImage {
          description
          file {
            url
          }
        }
      }
      secondaryTitleDescription
      rightPrimaryTitle
      buttonText
      heroImage {
        description
        file {
          url
        }
      }
      dotMatrixBackgroundImage {
        file {
          url
        }
      }
      donutBackgroundImage {
        file {
          url
        }
      }
      smallDonutBackgroundImage {
        file {
          url
        }
      }
      capsulesBackgroundImage {
        file {
          url
        }
      }
      headerDonutBackgroundImage {
        file {
          url
        }
      }
      sys {
        contentType {
          sys {
            id
          }
        }
      }
    }
  }
`;
