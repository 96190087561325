import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Link } from 'gatsby';
import ResearchTile from '../ResearchTile';
import { myStyles } from './styles';
import './ResourceHubResearch.scss';
import clsx from 'clsx';
import { urlgenerator } from '../../utils/url-generator';
import Container from '@material-ui/core/Container';

const ResourceHubResearch = ({ data, researchList, basePath, label, slug,backgroundImage,selectedTopic,selectedRegion,lunrResults,searchedTerm }) => {
  const numberOfTilesInResearch = process.env.GATSBY_NUMBER_OF_TILE_FOR_RESEARCH_IN_RESOURCEHUB;
  const classes = myStyles(backgroundImage);
  const colsList = [...researchList].splice(0, numberOfTilesInResearch).filter(research=>{
    return (
      (research.region === selectedRegion || selectedRegion === 'all') &&
      (research.categoryList?.find((el)=>el.categoryName===selectedTopic) || selectedTopic === 'all') &&
      (lunrResults.includes(research.contentful_id) || (!lunrResults.length && !searchedTerm))
    );
  })
  return (
    <>
    {colsList.length ? <Container maxWidth="xl" className='padding-none'>
      <Grid container className={clsx('research-cls', classes['research-cls'])}>
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className="research-container">
          <Grid item xl={2} lg={12} md={12} sm={12} xs={12} locId="researchTitle">
            <h2 gutterBottom className="title">
              {data.researchTitle}
            </h2>
          </Grid>
          <Grid
            container
            item
            xl={10}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="research-card-container"
          >
            {colsList.map((research) => (
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12} locId="researchSlug" key={research.researchSlug}>
                <Grid className="card-main-section">
                 <Card className="research-card" locId="research-tile">
                  <Link to={`/${basePath}/` + research.researchSlug} tabindex="0" className='research-card-link'>
                    <ResearchTile research={research} />
                  </Link>
                  </Card>
                </Grid>
              </Grid>
            ))}

            {/* More Blogs Text */}
            <Grid container spacing={1} className="more-research-text" locid="moreResearch">
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="more-research-col">
                <Link to={urlgenerator(basePath, slug)}>
                  <Typography className="more-research-text">{label}</Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Container>:<></>}
    </>
  );
};

export default ResourceHubResearch;
